export const isFunction = obj => {
  return !!(obj && obj.constructor && obj.call && obj.apply)
}

export const callListeners = (listeners, params) => {
  if (Array.isArray(listeners)) {
    for (const listener of listeners) {
      listener(params)
    }
  } else if (isFunction(listeners)) {
    listeners(params)
  }
}

export const addAdhocCallback = (listeners, newCallback) => {
  if (Array.isArray(listeners)) {
    return [...listeners, newCallback]
  } else if (isFunction(listeners)) {
    return [listeners, newCallback]
  } else if (!listeners) {
    return newCallback
  }
}

// Sync sleep function
export const sleep = ms => {
  var start = new Date().getTime()
  var expire = start + ms
  while (new Date().getTime() < expire) {}
}
