import SocketManager from './socket-manager'
import debug from 'debug'

const logger = debug('EventManager:connection')
const connection = new SocketManager()

export const createEvent = ({
  eventParams,
  isInitialiser = false
}) => {
  logger('creating event %o', eventParams)
  return connection.createEventManager({ eventParams, isInitialiser })
}

export const connect = ({
  eventBusURL,
  namespace,
  onOpen,
  onConnectionChange,
  onReconnect,
  onMaximum,
  onClose,
  onError,
  options
}) => {
  logger('connecting', eventBusURL, namespace, options)
  connection.connect({
    eventBusURL,
    namespace,
    onOpen,
    onConnectionChange,
    onReconnect,
    onMaximum,
    onClose,
    onError,
    options
  })
  return connection
}

export const disconnect = () => {
  logger('disconnecting %o', connection)
  connection.disconnect()
}
