import { useEffect } from 'react'

// Callback function when burst is available or change
export default (callback, events = []) => {
  useEffect(() => {
    if (!events.includes(undefined) && !events.includes(null)) {
      callback(events)
    }
  }, events)
}
