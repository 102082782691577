import Constants from '@emerald-works/constants'
import * as EventBus from '@emerald-works/event-bus-client'
import React, { useEffect, useState } from 'react'
import { SocketConnectionContext } from './context'
import useEventInitialiser from './hooks/use-event-initialiser'
import useEvent from './hooks/use-event'
import useEventsOnViewLoad from './hooks/use-events-on-view-load'
import { objectToQueryString } from './util'

let Auth = null

// @emerald-works/react-auth is a optional peer dependency
// So we may not found it when requiring it.
try {
  // Try to load it if fail it can be because local development
  // or because it's not needed.
  Auth = require('@emerald-works/react-auth')
} catch (_) {
  // workaround when `npm link`'ed for development
  try {
    var prequire = require('parent-require')
    Auth = prequire('@emerald-works/react-auth')
  } catch (error) {
    console.log(`
      Fail to load @emerald-works/react-auth from @emerald-works/react-event-bus-client.\n 
      Install it if you want to use auth token for event-bus
    `)
  }
}

const noop = () => {}

const EventBusProvider = ({
  eventBusURL,
  namespace,
  useAuthProvider = false,
  fetchUserProfile = false,
  LoadingComponent,
  waitForConnection = false,
  connectionParams,
  onOpen = noop,
  onConnectionChange = noop,
  onReconnect = noop,
  onMaximum = noop,
  onClose = noop,
  onError = noop,
  initialisers = [],
  options,
  ...props
}) => {
  const session = Auth?.useSession() || { generateEventBusToken: noop }

  // Create connected status state variable.
  // This is used to track the websocket connection state through GamaRayContext.
  const [isConnected, setIsConnected] = useState(false)

  // Setup initialisers listeners
  // Every function that is trigger on the "connection" event is an initialiser.
  // Data will be send to frontend if there is any listeners for that initialiser.
  useEventInitialiser(initialisers)

  // Open connection when component first render or on every eventBusURL and namespace change.
  // Once the component in unmounted: close the connection
  useEffect(() => {
    handleEventBusConnection()
    return () => {
      EventBus.disconnect()
    }
  }, [eventBusURL, namespace])

  const [getProfileEvent] = useEvent([
    {
      eventName: Constants.GET_CONTEXT_USER_PROFILE_EVENT,
      onSuccess: userProfile => session.updateSessionContext({ userProfile })
    }
  ])

  useEventsOnViewLoad(() => fetchUserProfile && getProfileEvent.trigger(), [getProfileEvent])

  // handle EventBus connection events.
  const handleEventBusConnection = () => {
    EventBus.connect({
      eventBusURL: async (attempt) => {
        const token = useAuthProvider ? await session.generateEventBusToken() : null
        const params = objectToQueryString({
          ...connectionParams,
          attempt,
          token
        })
        return `${eventBusURL}?${params}`
      },
      namespace,
      options: {
        ...options,
        maxAttempts: 3
      },
      onConnectionChange: status => {
        if (status === Constants.CONNECTION_SIGNAL_STATUS_GREEN) {
          setIsConnected(true)
        } else {
          setIsConnected(false)
        }
        onConnectionChange(status)
      },
      onClose: () => {
        setIsConnected(false)
        onClose()
      },
      onOpen: () => {
        onOpen()
      },
      onReconnect: () => {
        onReconnect()
      },
      onMaximum,
      onError
    })
  }

  const reloadConnection = () => {
    EventBus.disconnect()
    handleEventBusConnection()
  }

  // Render application if connection is opened or if user doesn't want to
  // wait for the connection.
  // If the user doesn't wait for the connection, is up to the user to check the connection status
  // using the context before blasting anything. Otherwise GammaRay will throw an error.
  const childrenElement = () => {
    return (
      <SocketConnectionContext.Provider value={{ isConnected, reloadConnection }}>
        {props.children}
      </SocketConnectionContext.Provider>
    )
  }

  return isConnected || !waitForConnection ? childrenElement() : LoadingComponent
}

export default EventBusProvider
