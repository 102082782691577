// In react, eventLM are created after the first render
// To avoid forcing the check on react components we're
// going to use this eventLM mock so the first render wont
// complain. As soon as the eventLM is created, react is going to react to it
// and update the UI making the calls to the right object.
export default class {
  constructor () {
    this.isWorking = false
    this.onStart = []
    this.onSuccess = []
    this.onStop = []
    this.onError = []
    this.onUnregister = []
    this.onAck = []
  }

  registerAdhocOnSuccessListener () { }
  registerAdhocOnStartListener () { }
  registerAdhocOnStopListener () { }
  registerAdhocOnErrorListener () { }
  registerAdhocOnAckListener () { }
  registerAdhocOnUnregisterListener () { }
  cancelTimeout () {}
  subscribeToEvent () {}
  trigger () {}
  unregister () {}
}
