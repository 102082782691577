import Sockette from 'sockette-dynamic-url'
import EventManager from './event-manager'
import {
  callListeners
} from './util'
import Constants from '@emerald-works/constants'

const noop = () => {}
const DEFAULT_OPTIONS = {
  timeout: 5e3,
  maxAttempts: 10,
  triggerTimeout: 30000
}

export default class SocketManager {
  constructor () {
    this.eventManagers = {}
    this.initialisers = {}
    this.connectionStatus = Constants.CONNECTION_SIGNAL_STATUS_RED
    this.payloadsWaitingForConnection = []
  }

  promise () {
    // return Promise
  }

  // red: no connection
  // yellow: connected but not ready to send messages
  // green: connected and ready to use
  isConnected () {
    return this.connectionStatus
  }

  _cleanPayloadWaitingForConnection () {
    this.payloadsWaitingForConnection = []
  }

  _addPayloadWaitingForConnection (payload) {
    this.payloadsWaitingForConnection.push(payload)
  }

  _processPayloadWaitingForConnection () {
    if (
      this.ws &&
      this.connectionStatus === Constants.CONNECTION_SIGNAL_STATUS_GREEN
    ) {
      for (const payload of this.payloadsWaitingForConnection) {
        this.sendJson(payload)
      }
      this._cleanPayloadWaitingForConnection()
    } else {
      console.log('Connection is not ready yet. Not processing payloads in queue to avoid infinity loop.')
    }
  }

  // Send Ping message to prevent API GATEWAY to idle Timeout
  _setPreventIdleTimeout () {
    this.idleTimeout = setTimeout(() => {
      this.sendJson({
        eventName: Constants.PING_EVENT
      })
    }, Constants.PING_REQUEST_IDLE_TIME_MS)
  }

  _resetPreventIdleTimeout () {
    if (this.idleTimeout) {
      clearTimeout(this.idleTimeout)
    }

    this._setPreventIdleTimeout()
  }

  _setConnectionStatus (status) {
    this.connectionStatus = status
    this.listeners.onConnectionChange
      ? this.listeners.onConnectionChange(this.connectionStatus)
      : noop()
    if (status === Constants.CONNECTION_SIGNAL_STATUS_GREEN) {
      this._resetPreventIdleTimeout()
      this._processPayloadWaitingForConnection()
    }
  }

  _processInitialiser (message) {
    if (!this.initialisers) {
      return
    }
    const event = this.initialisers[message.eventName]
    if (event) {
      if (message.payload && message.payload.error) {
        callListeners(event.onError, message.payload.error)
      } else {
        callListeners(event.onSuccess, message)
      }
    }
  }

  _processEventResponse (message) {
    if (!this.eventManagers) {
      return
    }

    const event = this.eventManagers[message.key]
    // console.log('onMessage', data, message, eventLM)
    if (event) {
      event.cancelTimeout()
      if (message.payload && message.payload.error) {
        callListeners(event.onError, message.payload.error)
      } else {
        callListeners(event.onSuccess, message)
        // if not realtime, unregister
      }

      callListeners(event.onStop, event)
    }
  }

  _processEventSideEffect (message) {
    const targetEvent = Object.values(this.eventManagers).find(
      event => event.eventName === message.eventName
    )

    if (targetEvent) {
      this._processEventResponse({
        ...message,
        key: targetEvent.key
      })
    } else if (
      Object.prototype.hasOwnProperty.call(this.initialisers, message.eventName)
    ) {
      this._processInitialiser(message)
    } else {
      console.info(`
        Trying to process event sideEffect for event name: ${message.eventName} but no event was found for it in eventManagers or initialisers. 
        Create a event to handle it in the component context or add it as a initialiser.
      `)
    }
  }

  connect ({
    eventBusURL,
    namespace,
    onOpen = noop,
    onConnectionChange = noop,
    onReconnect = noop,
    onMaximum = noop,
    onClose = noop,
    onError = noop,
    options
  }) {
    this.eventBusURL = eventBusURL
    this.namespace = namespace
    this.listeners = {
      onOpen,
      onConnectionChange,
      onReconnect,
      onMaximum,
      onClose,
      onError
    }

    this.opts = {
      ...DEFAULT_OPTIONS,
      ...options
    }

    this.sockette = new Sockette(this.eventBusURL, {
      ...this.opts,
      onopen: event => {
        setTimeout(() => {
          this.ws = event.target
          this._setConnectionStatus(
            Constants.CONNECTION_SIGNAL_STATUS_YELLOW
          )
          this.listeners.onOpen ? this.listeners.onOpen() : noop()
        }, 10)
      },
      onmessage: ({
        data
      }) => {
        this._resetPreventIdleTimeout()
        const message = JSON.parse(data)

        const onMessageHandlers = {
          [Constants.MESSAGE_TYPE_CONNECTION_SIGNAL]: () => {
            this._setConnectionStatus(message.payload.connectionSignal)
          },
          [Constants.MESSAGE_TYPE_RESPONSE]: () => {
            // Default event result
            if (message.key) {
              this._processEventResponse(message)
            } else {
              // sideEffect eventLM result
              this._processEventSideEffect(message)
            }
          },
          [Constants.MESSAGE_TYPE_ACK]: () => {
            if (!this.eventManagers) {
              return
            }

            const eventLM = this.eventManagers[message.key]
            // console.log('onMessage', data, message, eventLM)
            if (eventLM) {
              callListeners(eventLM.onAck, message.payload)

              if (
                message.payload.responseNeedsSubscribe &&
                message.payload.responseDestination !== eventLM.name
              ) {
                eventLM.cancelTimeout()
                callListeners(eventLM.onStop, eventLM)
              }
            }
          },
          [Constants.MESSAGE_TYPE_INITIALISER]: () => {
            this._processInitialiser(message)
          }
        }

        if (
          Object.prototype.hasOwnProperty.call(onMessageHandlers, message.type)
        ) {
          onMessageHandlers[message.type]()
        } else {
          console.info(`No processing for message: ${message}`)
        }
      },
      onreconnect: this.listeners.onReconnect || noop,
      onmaximum: this.listeners.onMaximum || noop,
      onclose: event => {
        this.ws = undefined
        this._setConnectionStatus(
          Constants.CONNECTION_SIGNAL_STATUS_RED
        )
        this.listeners.onClose ? this.listeners.onClose() : noop()
      },
      onerror: this.listeners.onError || noop
    })
  }

  disconnect () {
    try {
      this.ws.close()
      this.ws = undefined
      this._setConnectionStatus(
        Constants.CONNECTION_SIGNAL_STATUS_RED
      )
    } catch (err) {
      console.log('err', err)
    }
  }

  createEventManager ({
    eventParams,
    isInitialiser = false
  }) {
    // initialiser can't subscribe
    const normalizedParams = isInitialiser ? {
      ...eventParams,
      connection: this,
      canSubscribe: false,
      subscribeOnInit: false
    } : {
      connection: this,
      ...eventParams
    }

    const event = new EventManager(normalizedParams)

    if (isInitialiser) {
      this.initialisers[event.eventName] = event
    } else {
      this.eventManagers[event.key] = event
    }

    return event
  }

  unregister (event) {
    // console.log(event)
  }

  sendJson (payload) {
    if (
      this.ws &&
      this.connectionStatus === Constants.CONNECTION_SIGNAL_STATUS_GREEN
    ) {
      this.ws.send(JSON.stringify(payload))
      this._resetPreventIdleTimeout()
    } else {
      this._addPayloadWaitingForConnection(payload)
    }
  }
}
