import React, { useContext, useState, useEffect, createContext } from 'react'
import DefaultLoadingAuthPage from './components/LoadingAuthPage'
import DefaultAuthenticatingPage from './components/AuthenticatingPage'
import DefaultStubbedPage from './components/StubbedPage'
import DefaultNotAuthorizedPage from './components/NotAuthorizedPage'
import { Auth, API } from 'aws-amplify'
import { Routes, Route } from 'react-router-dom'
import SignInCompletion from './SignInCompletion'

const EVENT_BUS_TOKEN_API_NAME = 'event_bus_token_api_name'

const SessionContext = createContext(null)
const AuthConfigContext = createContext(null)

export const useSession = () => {
  return useContext(SessionContext)
}
export const useAuthConfig = () => {
  return useContext(AuthConfigContext)
}

const defaultOnAuthNeededRedirection = () => {
  console.error(
    "AuthProvider doesn't handle the Auth redirection (where is the page the user authenticate). You must provide the onAuthNeededRedirection prop to AuthProvider."
  )
}

const AuthProvider = ({
  allowGuestUsers = false,
  allowedUserGroups = /.*/,
  afterAuthLocationCookieName = 'after-auth-location',
  LoadingAuthComponent = <DefaultLoadingAuthPage />,
  AuthenticatingAuthComponent = <DefaultAuthenticatingPage />,
  StubbedComponent = <DefaultStubbedPage />,
  NotAuthorizedComponent = <DefaultNotAuthorizedPage />,
  onAuthNeededRedirection = defaultOnAuthNeededRedirection,
  eventBusAuthTokenConfig = { generate: false },
  signInPath,
  signOutPath,
  ...props
}) => {
  // function to update the isAuthenticated session value
  const setIsAuthenticated = value => {
    setSession({
      ...session,
      isAuthenticated: value
    })
  }

  const [eventBusAuthToken, setEventBusAuthToken] = useState()
  const [isAuthCheckDone, setIsAuthCheckDone] = useState(false)
  const [signInCompletionURL, setSignInCompletionURL] = useState()
  const [session, setSession] = useState({
    setIsAuthenticated,
    isAuthenticated: false
  })
  const [authConfig] = useState({
    allowGuestUsers,
    allowedUserGroups,
    afterAuthLocationCookieName,
    LoadingAuthComponent,
    AuthenticatingAuthComponent,
    StubbedComponent,
    NotAuthorizedComponent,
    onAuthNeededRedirection
  })

  const generateEventBusToken = async () => {
    if (eventBusAuthTokenConfig.generate) {
      const { isAuthenticated } = session
      const tokenEndPoint = eventBusAuthTokenConfig.generateTokenEndpoint
      const tokenURL =
        process.env.REACT_APP_ENVIRONMENT === 'local'
          ? `${tokenEndPoint}?isAuthenticated=${isAuthenticated}`
          : tokenEndPoint
      const { token } = await API.get(EVENT_BUS_TOKEN_API_NAME, tokenURL)
      setEventBusAuthToken(token)
      return token
    } else {
      console.log(
        "You're trying to generate a token for the event bus but you didn't pass the right eventBusAuthTokenConfig to AuthProvider Component"
      )
    }
  }

  const checkSession = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      const session = await Auth.currentSession()
      const credentials = await Auth.currentUserCredentials()

      return {
        user,
        session,
        credentials,
        isAuthenticated: true,
        setIsAuthenticated
      }
    } catch (error) {
      let credentials = null

      if (allowGuestUsers) {
        credentials = await Auth.currentCredentials()
      }

      return {
        user: null,
        session: null,
        credentials,
        isAuthenticated: false,
        setIsAuthenticated
      }
    }
  }

  const updateSessionContext = async extra => {
    const newSession = await checkSession()
    setSession({
      ...extra,
      ...newSession
    })
    setIsAuthCheckDone(true)
  }

  useEffect(() => {
    if (props.amplifyAuthConfig) {
      Auth.configure(props.amplifyAuthConfig)
    } else {
      throw new Error(
        'You need to pass the amplifyAuthConfig prop to AuthProvider'
      )
    }

    if (eventBusAuthTokenConfig.generate) {
      API.configure({
        endpoints: [
          {
            name: EVENT_BUS_TOKEN_API_NAME,
            endpoint: eventBusAuthTokenConfig.apiGatewayAddress,
            region: eventBusAuthTokenConfig.awsRegion
          }
        ]
      })
    }

    if (signInPath) {
      setSignInCompletionURL(signInPath)
    } else {
      const signInURL = new URL(props.amplifyAuthConfig.oauth.redirectSignIn)
      setSignInCompletionURL(signInURL.pathname)
    }

    updateSessionContext()
  }, [props.amplifyAuthConfig])

  return (
    <AuthConfigContext.Provider value={authConfig}>
      <SessionContext.Provider
        value={{
          ...session,
          updateSessionContext,
          generateEventBusToken,
          eventBusAuthToken
        }}
      >
        {signInCompletionURL && (
          <Routes>
            <Route
              path={signInCompletionURL}
              element={
                <SignInCompletion children={AuthenticatingAuthComponent} />
              }
            />
          </Routes>
        )}
        {isAuthCheckDone && props.children}
      </SessionContext.Provider>
    </AuthConfigContext.Provider>
  )
}

export default AuthProvider
