import React from 'react'

//  Default Component to show when StubbedPage auth page.
const StubbedPage = () => {
  return (
    <div>
      <h4> Read this article by signing up </h4>
      <p> You've read all you free articles this week. </p>
    </div>
  )
}

export default StubbedPage
