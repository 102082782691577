import { useState } from 'react'

export default (eventManagers = []) => {
  const initialState = {}

  for (const eventManager of eventManagers) {
    initialState[eventManager.key] = false
  }

  const [
    eventManagersWorkingState,
    setEventManagersWorkingState
  ] = useState(initialState)

  const updateEventManagersWorkingState = (
    eventManager,
    state
  ) => {
    setEventManagersWorkingState({
      ...eventManagersWorkingState,
      [eventManager.key]: state
    })
  }

  return [
    eventManagersWorkingState,
    updateEventManagersWorkingState
  ]
}
