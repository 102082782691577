import generateUniqueId from 'generate-unique-id'
import {
  callListeners,
  sleep,
  addAdhocCallback,
  isFunction
} from './util'

const noop = () => { }

export default class EventManager {
  constructor ({
    connection,
    canSubscribe = true,
    shouldTimeout = true,
    subscribeOnTrigger = false,
    subscribeOnInit = false,
    eventName,
    onStart = noop,
    onSuccess = noop,
    onStop = noop,
    onError = noop,
    onUnregister = noop,
    onAck = noop
  }) {
    this.connection = connection
    this.eventName = eventName
    this.key = generateUniqueId()
    this.connection = connection
    this.canSubscribe = canSubscribe
    this.shouldTimeout = shouldTimeout
    this.subscribeOnInit = subscribeOnInit
    this.subscribeOnTrigger = subscribeOnTrigger

    this.onStart = onStart
    this.onSuccess = onSuccess
    this.onStop = onStop
    this.onError = onError
    this.onUnregister = onUnregister
    this.onAck = onAck

    if (this.subscribeOnInit) {
      this.subscribeToEvent()
    }
  }

  cancelTimeout () {
    this.shouldTimeout = false
  }

  subscribeToEvent (payload, requestId) {
    if (this.canSubscribe) {
      const json = {
        subscribeTo: this.eventName,
        eventName: 'subscribe',
        payload,
        app: this.connection.app,
        key: this.key,
        requestId: requestId || generateUniqueId()
      }
      this.connection.sendJson(json)
      // sleep for 150 ms to avoid dynamo propagation mismatch
      sleep(150)
    }
  }

  trigger (payload) {
    const requestId = generateUniqueId()

    if (this.subscribeOnTrigger) {
      this.subscribeToEvent(payload, requestId)
    }

    callListeners(this.onStart, this)

    this.connection.sendJson({
      eventName: this.eventName,
      key: this.key,
      payload,
      requestId
    })
    setTimeout(() => {
      if (this.shouldTimeout) {
        callListeners(this.onError, 'The upstream trigger timed out.')
        callListeners(this.onStop, this)
      }
    }, this.connection.opts.triggerTimeout)
  }

  // Method
  unregister () {
    const json = {
      unsubscribeTo: this.eventName,
      eventName: 'unsubscribe',
      payload: { key: this.key, unsubscribeTo: this.eventName },
      app: this.connection.app,
      key: this.key
    }
    if (this.connection) {
      this.connection.sendJson(json)
    }
    callListeners(this.onUnregister)
  }

  _registerAdhocListener (callback, lifecycleEvent) {
    if (isFunction(callback)) {
      switch (lifecycleEvent) {
        case 'onStart':
          this.onStart = addAdhocCallback(this.onStart, callback)
          break
        case 'onSuccess':
          this.onSuccess = addAdhocCallback(this.onSuccess, callback)
          break
        case 'onStop':
          this.onStop = addAdhocCallback(this.onStop, callback)
          break
        case 'onError':
          this.onError = addAdhocCallback(this.onError, callback)
          break
        case 'onUnregister':
          this.onUnregister = addAdhocCallback(this.onUnregister, callback)
          break
        case 'onAck':
          this.onAck = addAdhocCallback(this.onAck, callback)
          break
        default:
          console.log('Unknow event lifecycle event')
          break
      }
    } else {
      console.log('Adhoc listeners must be functions')
    }
  }

  registerAdhocOnSuccessListener (listener) {
    this._registerAdhocListener(listener, 'onSuccess')
  }

  registerAdhocOnStartListener (listener) {
    this._registerAdhocListener(listener, 'onStart')
  }

  registerAdhocOnStopListener (listener) {
    this._registerAdhocListener(listener, 'onStop')
  }

  registerAdhocOnErrorListener (listener) {
    this._registerAdhocListener(listener, 'onError')
  }

  registerAdhocOnAckListener (listener) {
    this._registerAdhocListener(listener, 'onAck')
  }

  registerAdhocOnUnregisterListener (listener) {
    this._registerAdhocListener(listener, 'onUnregister')
  }
}
