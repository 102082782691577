import React, { useEffect, useState } from 'react'
import { Route, useLocation } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import Cookies from 'universal-cookie'
import { useSession, useAuthConfig } from './AuthProvider'

const Authenticate = () => {
  const location = useLocation()
  const authConfig = useAuthConfig()
  const cookies = new Cookies()
  const cookieKey = authConfig.afterAuthLocationCookieName
  const targetURI = `${location.pathname}${location.search}`
  cookies.set(cookieKey, targetURI, { path: '/' })

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .catch(() => {
        authConfig.onAuthNeededRedirection({
          Auth,
          federatedSignInParams: { customState: cookieKey },
          location
        })
      })
  }, [])

  return authConfig.LoadingAuthComponent
}

const PrivateRoute = ({
  element,
  allowedUserGroups,
  shouldShowStubbedPage = false,
  shouldCheckAuthentication = true,
  ...rest
}) => {
  const { isAuthenticated, session } = useSession()
  const authConfig = useAuthConfig()
  const [isStubbed, setIsStubbed] = useState()
  const [checkAuth, setCheckAuth] = useState()
  const userGroups = session?.accessToken?.payload?.['cognito:groups']?.join(',') || 'defaultUserGroup'
  const userHasApplicationAccess = userGroups.match(allowedUserGroups || authConfig.allowedUserGroups)?.length > 0
  const isBoolean = val => typeof val === 'boolean'

  useEffect(() => {
    if (isBoolean(shouldShowStubbedPage)) {
      setIsStubbed(shouldShowStubbedPage)
    } else {
      setIsStubbed(shouldShowStubbedPage({ isAuthenticated, ...rest }))
    }

    if (isBoolean(shouldCheckAuthentication)) {
      setCheckAuth(shouldCheckAuthentication)
    } else {
      setCheckAuth(shouldCheckAuthentication({ isAuthenticated, ...rest }))
    }
  }, [])

  // return stubbed page if stubbed
  if (isStubbed) {
    return <Route {...rest} element={authConfig.StubbedComponent} />
  }

  // return element page if route doesn't required auth check
  if (!checkAuth) {
    return <Route {...rest} element={element} />
  }

  // return the Authenticate component if not authenticated
  if (!isAuthenticated) {
    return <Route {...rest} element={<Authenticate />} />
  }

  // return user no authorized component if user has no application access
  if (!userHasApplicationAccess) {
    return <Route {...rest} element={authConfig.NotAuthorizedComponent} />
  }

  // return page
  return <Route {...rest} element={element} />
}

export default PrivateRoute
